<template>
	<div class="calc_box widthMin">
		<div class="calc_cont">
			<div class="calc_l calc_p q_table">
				<div class="c_title">还款计算器</div>
				<el-form ref="ruleForm" :rules="rules" :model="form" class="calFrom" label-width="90px">
				  <el-form-item label="贷款金额" prop="amount">
				    <el-input :onkeyup="form.amount=form.amount.replace(/^(0+)|[^\d]+/g,'')" v-model="form.amount" ></el-input>
				  </el-form-item>
				  <el-form-item label="年化利率" prop="rate">
					  <!--    /[^\d^\.]+/g    ^((/d{1,2}(/./d{1,2})?)|100|100.00)$ -->
				    <el-input :onkeyup="form.rate=form.rate.replace(/[^\d^\.]+/g,'')" v-model="form.rate" > </el-input>
				     <span style="position: absolute;right: -30px;">%</span>
				  </el-form-item>
				  <el-form-item label="还款方式" prop="type">
				    <el-select v-model="form.type" placeholder="请选择还款方式" class="c_block">
					    <el-option v-for="item in options":key="item.value" :label="item.label":value="item.value"> </el-option>
					</el-select>
				  </el-form-item>
				  <el-form-item label="开始时间" prop="startTime">
				    <el-date-picker class="c_block" format="yyyy 年 MM 月 dd 日" value-format="yyyy-MM-dd" v-model="form.startTime"  type="date" placeholder="选择日期"></el-date-picker>
				  </el-form-item>
				  <el-form-item label="结束时间" prop="endTime">
				    <el-date-picker  :picker-options="pickerOptionsEnd" class="c_block" format="yyyy 年 MM 月 dd 日" value-format="yyyy-MM-dd" v-model="form.endTime"type="date"placeholder="选择日期"></el-date-picker>
				  </el-form-item>
				  <el-form-item label="本金周期" prop="principalCycle">
				    <el-input :onkeyup="form.principalCycle=form.principalCycle.replace(/^(0+)|[^\d]+/g,'')" v-model="form.principalCycle" placeholder="间隔多少天一期本金账单" >
				    </el-input>
				    <span style="position: absolute;right: -30px;">天</span>
				  </el-form-item>
				  <el-form-item label="利息周期" prop="interestCycle">
				    <el-input :onkeyup="form.interestCycle=form.interestCycle.replace(/^(0+)|[^\d]+/g,'')" v-model="form.interestCycle" placeholder="间隔多少天一期利息账单" >
				    </el-input>
				    <span style="position: absolute;right: -30px;">天</span>
				  </el-form-item>
				  <el-form-item>
				    <el-button type="primary" @click="onSubmit" class="btnwh">计算</el-button>
				    <el-button @click="resetForm('ruleForm')" class="btnwh">重置</el-button>
				  </el-form-item>
				  <el-form-item>
				    <div class="tipsBox">
					  	<img src="../../assets/images/i1.png"/>
					  	<div class="tips_text">
					  		温馨提示：本个人贷款计算器测算数据仅供参考，具体以 办理业务或交易实际结果为准
					  	</div>
					</div>
				  </el-form-item>
				  
				</el-form>
			</div>
			<div class="calc_r calc_p2 calFrom q_table">
				<div class="c_title">还款/账单详情</div>
				<el-form  :model="h_form" label-position="left" disabled label-width="90px">
				  <el-form-item label="还款方式：">
				    <el-input v-model="h_form.type_name" style="width: 360px;"></el-input>
				  </el-form-item>
				  <el-form-item label="期限天数：">
				     <div style="display: flex;">
				    	<el-input v-model="h_form.h_time" ></el-input>
				        <span style="margin-left: 10px;">天</span>
				    </div>
				  </el-form-item>
				  <el-form-item label="本金总额：">
				    <div style="display: flex;">
				       <el-input v-model="h_form.amount" ></el-input>
				       <span style="margin-left: 10px;">元</span>
				    </div>
				  </el-form-item>
				  <el-form-item label="利息总额：">
				    <div style="display: flex;">
				    	<el-input v-model="h_form.totalInterest" ></el-input>
				        <span style="margin-left: 10px;">元</span>
				    </div>
				  </el-form-item>
				</el-form>
				<el-table :data="tableData" border height="300" style="width: 100%;max-height:300px;overflow: hidden;overflow-y: auto;border-radius: 4px;">
			      <el-table-column prop="date" label="期数" align="center" header-align="center">
			      	<template slot-scope="scope">
			      		<span>第{{ scope.$index+1 }}期</span>
			      	</template>
			      </el-table-column>
			      <el-table-column prop="repaymentTime"label="还款日期" align="center" header-align="center"></el-table-column>
			      <el-table-column prop="principal"label="应还本金" align="center" header-align="center"></el-table-column>
			      <el-table-column prop="interest"label="应还利息" align="center" header-align="center"></el-table-column>
			      <!--<el-table-column prop="surplusAmount"label="剩余本金" align="center" header-align="center">-->
			      </el-table-column>
			    </el-table>
			</div>
		</div>
	</div>
</template>

<script>
	import {calculator} from "@/assets/js/api";
	export default {
		name:'CalcCom',
		data() {
			var checkRate = (rule, value, callback) => {
			    if (!value) {
			        return callback(new Error('年化利率不能为空'))
			    }
			    if (!/^((\d|[1-9]\d|100.00)(\.\d{1,2})?)$/.test(value)) {
			        callback(new Error('请填写正确的年化利率格式,保留两位小数'))
			    } else {
			        callback()
			    }
			}
			return {
				form: {
				  amount:'',//贷款金额
				  rate:'',//年华利率
		          startTime:'',
		          endTime:'',
		          type:'',//借款类型 	类型1等额本金2先息后本
		          principalCycle:'',//本金周期
		          interestCycle:'',//利息周期
		        },
		        h_form:{},
		        options:[
		         {value:1,label:'等额本金'},
		         {value:2,label:'先息后本'},
		        ],
		        
		        rules: {
		          amount: [
		            { required: true, message: '请填写贷款金额', trigger: 'blur' }
		          ],
				  // ,
		          rate: [
		            { required: true,validator: checkRate, trigger: 'blur' }
		          ],
		          type: [
		            { required: true, message: '请选择贷款类型', trigger: 'change' }
		          ],
		          startTime: [
		            { required: true, message: '请选择开始时间', trigger: 'change' }
		          ],
		          endTime: [
		            { required: true, message: '请选择结束时间', trigger: 'change' }
		          ],
		          principalCycle: [
		            { required: true, message: '请填写本金周期', trigger: 'blur' }
		          ],
		          interestCycle: [
		            { required: true, message: '请填写利息周期', trigger: 'blur' }
		          ],
		        },
		        
		        tableData: [],
		        //设置结束时间大于开始时间
		        pickerOptionsEnd: {
			        disabledDate: time => {
			            let beginDateVal = this.form.startTime;
			            if (beginDateVal) {
			               return (time.getTime()<new Date(beginDateVal).getTime());
			            }
			        }
			    }
			}
		},
		methods:{
			onSubmit() {
				console.log(this.form,'this.form')
				this.$refs['ruleForm'].validate((valid) => {
		          if (valid) {
		            let data = this.form;
	                calculator(this.form).then((res)=>{
			          if(res.code === '1'){
			          	this.tableData = res.data;
			          	this.h_form.type_name = data.type==1?'等额本金':'先息后本';
		                this.h_form.h_time = this.commonEvent.getDaysBetween(data.startTime,data.endTime);
		                this.h_form.amount = data.amount;
		                this.h_form.totalInterest = this.totalInterest(res.data);
			          }else{
			            this.$message.error(res.msg)
			          }
			        },
			        (err)=>{
			          console.log(err)
			        })
		          } else {
		            return false;
		          }
		        });
			},
			
			//计算利息总额
			totalInterest(arr){
				let price = 0;
				if(arr.length>0){
					for(var i = 0;i<arr.length;i++){
						price+=arr[i].interest;
					}
				}
				return price.toFixed(2)
			},
			resetForm(formName) {
		        this.$refs[formName].resetFields();
		        this.h_form = {};
		        this.tableData = [];
		    }
		}
	}
</script>
<style>
	.calFrom input{
	  	height: 45px;
	  	border: 1px solid #D6D6D6;
		border-radius: 4px;
	 }
	.el-input.is-disabled .el-input__inner{
	 	background: #EEEEEE;
		border: 1px solid #D6D6D6;
	}
	.el-table td, .el-table th.is-leaf,.el-table--border, .el-table--group{
	  border-color: #E5E5E5; 
	}
	.el-table--border::after, .el-table--group::after, .el-table::before{
	  background-color: #E5E5E5;
	}
	.el-table td{
		background: #FAFAFA;
	}
	.q_table .el-form-item__label{
		font-weight: bold;
		color: #101010;
	}
	.el-input-group__append{
		background-color: #EEEEEE;
	}
</style>
<style scoped="scoped">
  .calc_cont{
  	background-color: #FFFFFF;
  	display: flex;
  	width: 100%;
  	padding:30px;
  	max-height: 750px;
  	box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
  	border: 1px solid #E5E5E5;
    border-radius: 10px;
  }
 
  .calc_p{
  	flex: 1;
  	padding:0 80px 0 50px;
  }
  .calc_p2{
  	flex: 1;
  	padding:0 0px 0 80px;
  }
  .calc_r{
  	border-left: 1px dashed gainsboro;
  }
  .c_title{
  	padding: 10px 0 30px 0;
  	font-weight: bold;
  	font-size: 16px;
  	position: relative;
  	padding-left: 26px;
  }
  .c_title:after{
  	content: '';
  	width: 6px;
	height: 24px;
	background: #016FF1;
	position: absolute;
	left: 0;
	top: 10px;
  }
  .c_block{
  	display: block!important;
  	width: 100%;
  }
  .btnwh{
  	width: 112px;
  	height: 50px;
  }
  
  /*提示*/
 .tipsBox{
 	display: flex;
 	align-items: flex-start;
 }
 .tipsBox img{
 	width: 16px;
 	height: 16px;
 }
 .tips_text{
 	line-height: 20px;
 	color: #FC171C;
 	margin-left: 5px;
 }
</style>