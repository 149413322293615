<template>
	<div class="Financing">
		<div class="tabs">
			<div class="tab_item" :class="activeIndex==1?'activeClass':''" @click="activeTab(1)">智慧融资</div>
			<div class="tab_item" :class="activeIndex==2?'activeClass':''" @click="activeTab(2)">发布融资意向</div>
			<div class="tab_item" :class="activeIndex==3?'activeClass':''" @click="activeTab(3)">融资计算器</div>
		</div>
		<div class="showBox2" style="padding-bottom: 100px;" v-if="activeIndex==2">
			<div style="width: 1200px;margin: 30px auto;background-color: #FFFFFF;padding: 50px 0;">
				<qsetp :setpArr="setpArr" :current="1"></qsetp>
			</div>
			<div>
				<ApplyFinancing></ApplyFinancing>
			</div>
		</div>
		<div class="showBox3" style="padding-bottom: 100px;" v-if="activeIndex==3">
			<div class="calc">
				<calc-com class="calcBox"></calc-com>
			</div>
		</div>
		<div class="showBox1" style="background-color: #FFFFFF;" v-if="activeIndex==1">
		<div class="widthMin pt-5 mb-2 ">
			<h2 class="font4 active mb-2">拆迁融资的区别</h2>
			<h4 class="font24 cgrey mb-5">FINANCE DIFFERENCES</h4>
			<div class="row cgrey2">
				<div class="col-lg-7">
					<div class="d-flex ">
						<ul>
							<h5 class="mb-3 font24">普通</h5>
							<li class="mb-2">可申请融资金额较小</li>
							<li class="mb-2">抵押物有限，不支持拆迁中的设备 </li>
							<li class="mb-2">手续较多，办理麻烦</li>
							<li class="mb-2">审批繁琐且难，下款慢</li>
							<li>融资渠道可持续性差，不稳定</li>
						</ul>
						<img src="../../assets/images/web_h5/f_1.png" class="widthImg">
					</div>
					<div class="row">
						<ul class="col-5">
							<h5 class="mb-3 font24">好多资产平台</h5>
							<li class="mb-2">拆迁设备等资产可作为抵押物</li>
							<li class="mb-2">超高额度，最高能达到抵押物评估价的70%</li>
							<li class="mb-2">手续、流程少，审批速度快</li>
							<li class="mb-2">专业出资机构，稳定可靠可持续</li>
							<li>附加服务多，一次融资，多处受益</li>
						</ul>
						<img src="../../assets/images/web_h5/f_2.png" class="col-5 col-lg-6">
					</div>

				</div>
				<div class="col-lg-5 text-center mt-md-5">
					<img :src="rz_f_3" class=" img-thumbnail">
				</div>

			</div>
		</div>

		<!-- step -->
		<div class="steps widthMin mb-5 pb-3 mt-5 pl-md-2 pr-md-2">
			<div class="d-flex justify-content-center position-relative  mb-3">
				<div class="steps-btn ">现在去融资</div>
				<hr class="position-absolute srep-hr">
			</div>
			<h5 class="text-center cgrey">简单填写项目资料、即可完成申请</h5>
		</div>
		<!--  好多资产为什么能让拆迁项目“获得大额融资 -->
		<div class="widthMin problem mb-5">
			<div class="row">
				<div class="col-md-9">
					<h2 class="font4 active mb-2">好多资产为什么能让拆迁项目“获得大额融资”?</h2>
					<h4 class="font28 cgrey">Make Finance</h4>
					<h5 class="cgrey2 mt-2 mt-md-5 pt-md-5 font28">因为好多资产平台 您可以通过抵押拆迁物获得融资</h5>
				</div>
				<img src="../../assets/images/web_h5/f_4.png" class="col-md-3">

			</div>

		</div>
		<!-- 帮拆迁方处置 -->
		<div class="pledge widthMin">
			<div class="row text-center">
				<div class="col-lg-4 mb-md-5 pledge-txt">
					<div class="card">
						<div class="position-relative">
							<img :src="rz_index_5" class="card-img-top" alt="...">
							<div class="position-absolute hlp-shad d-flex align-items-center justify-content-center">
								拆迁物抵押获得贷款
							</div>
						</div>
						<ul class="card-body">
							<li class="card-text mb-2 ">好多资产支持拆迁物抵押贷款</li>
							<li class="card-text mb-2">放款额度根据您的资质有所不同</li>
							<li class="card-text mb-2">最高可达到抵押物评估家的70%</li>
							<li class="card-text mb-2">资金由平台及专业出资方共同投入</li>
							<li class="card-text mb-2"> 您只需要发布关于您项目的</li>
							<li class="card-text mb-2"> 拆迁项目相关说明 </li>
							<li class="card-text mb-2">就有专业机构与您联系</li>
							<li class="card-text "> 全程省心、省力</li>
						</ul>
					</div>
				</div>
				<div class="col-lg-4 mb-md-5 pledge-txt">
					<div class="card">
						<div class="position-relative">
							<img :src="rz_index_7" class="card-img-top" alt="...">
							<div class="position-absolute hlp-shad d-flex align-items-center justify-content-center">
								委托平台处置抵押物
							</div>
						</div>
						<ul class="card-body">
							<li class="card-text mb-2 ">为保障出资方利益</li>
							<li class="card-text mb-2">获得拆迁抵押物贷款的融资客户</li>
							<li class="card-text mb-2 ">须委托平台协助处置及收款 </li>
							<li class="card-text mb-2">平台将依托30万+海量全网客户</li>
							<li class="card-text mb-2 ">以及6000余家合作伙伴</li>
							<li class="card-text mb-2">通过预售会等形式</li>
							<li class="card-text mb-2">帮您更快、更高价的变卖</li>
							<li class="card-text">变卖款平台将自动扣除约定比例的还款金</li>
						</ul>
					</div>
				</div>
				<div class="col-lg-4  pledge-txt">
					<div class="card">
						<div class="position-relative">
							<img :src="rz_index_15" class="card-img-top" alt="...">
							<div class="position-absolute hlp-shad d-flex align-items-center justify-content-center">
								抵押物管理及保全服务
							</div>
						</div>
						<ul class="card-body">
							<li class="card-text mb-2 "> 为保全抵押物不流失</li>
							<li class="card-text mb-2">获得拆迁抵押物贷款的融资客户</li>
							<li class="card-text mb-2 ">须委托并接受平台的资产管理服务</li>
							<li class="card-text mb-2">支持小程序及网页端的</li>
							<li class="card-text mb-2 ">管理数据可视化 </li>
							<li class="card-text mb-2 ">账目清晰、资产分布一目了然 </li>
							<li class="card-text mb-2"> 若资产异常系统将会及时通知出资方</li>
							<li class="card-text">以便于您和平台及出资机构的长期合作</li>
						</ul>
					</div>
				</div>
			</div>
		</div>

		<!-- 服务流程 -->
		<div class="serve text-center bg-ser text-white mt-5 pl-md-2 pr-md-2 pb-4" :style="{'background-image': 'url('+rz_f_14+')'}">
			<div class="widthMin">
				<h1 class="font4 pt-5 mb-3 mt-2">服务流程</h1>
				<h5 class="mb-5 font28">Make Finance</h5>
				<ul class="row d-flex align-items-center ">
					<li class="col-2 icon-img ">
						<img src="../../assets/images/web_h5/f_5.png" class="mb-2">
						<p>在线填写融资申请</p>
					</li>
					<li class="col">
						<img src="../../assets/images/web_h5/f_15.png" alt="">
					</li>
					<li class="col-2 icon-img">
						<img src="../../assets/images/web_h5/f_6.png" class="mb-2">
						<p>商务对接</p>
					</li>
					<li class="col">
						<img src="../../assets/images/web_h5/f_15.png" alt="">
					</li>
					<li class="col-2 icon-img">
						<img src="../../assets/images/web_h5/f_7.png" class="mb-2">
						<p>大数据及专业评估</p>
					</li>
					<li class="col">
						<img src="../../assets/images/web_h5/f_15.png" alt="">
					</li>
					<li class="col-2 icon-img">
						<img src="../../assets/images/web_h5/f_8.png" class="mb-2">
						<p>各机构参与出资报价</p>
					</li>

				</ul>

				<div class="d-flex justify-content-end trans-cirle pt-5 mt-2 mb-4 pr-xl-4 pr-lg-2">
					<img src="../../assets/images/web_h5/f_15.png" alt="" class="mr-4 pr-3">
				</div>
				<ul class="row d-flex align-items-center widthMin pb-5">
					<li class="col-2 icon-img">
						<img src="../../assets/images/web_h5/f_9.png" class="mb-2">
						<p>在线填写融资申请</p>
					</li>
					<li class="col">
						<img src="../../assets/images/web_h5/f_15.png" alt="">
					</li>
					<li class="col-2 icon-img">
						<img src="../../assets/images/web_h5/f_10.png" class="mb-2">
						<p>达成合作，签订合同</p>
					</li>
					<li class="col">
						<img src="../../assets/images/web_h5/f_15.png" alt="">
					</li>
					<li class="col-2 icon-img">
						<img src="../../assets/images/web_h5/f_11.png" class="mb-2">
						<p>委托平台处置及保全</p>
					</li>
					<li class="col">
						<img src="../../assets/images/web_h5/f_15.png" alt="">
					</li>
					<li class="col-2 icon-img">
						<img src="../../assets/images/web_h5/f_12.png" class="mb-2">
						<p>享受海量买家与管理服务</p>
					</li>

				</ul>
			</div>

		</div>
		<!-- 资产管理保全服务 -->
		<div class="manage text-center pl-md-2 pr-md-2 ">
			<h1 class="font4 pt-5 mb-3 mt-2 active">资产管理保全服务</h1>
			<h5 class="mb-5 font28 cgrey">Data center</h5>
			<p class="cgrey mt-4 mb-4">一次融资，多处收益，为保障您和资方的利益，使拆迁管理规范化、效率化，提升您的管理及处置效率，平台将为您提供对应配套服务</p>
			<img :src="rz_f_13" class="card-img" alt="...">
		</div>
		</div>
	</div>
</template>

<script>
	import CalcCom from '@/components/calc/calcCommon.vue';//融资计算器组件
	import ApplyFinancing from '../applyFinancing/applyFinancing.vue';//融资计算器组件
	export default {
		components: {
		   CalcCom,
		   ApplyFinancing
		},
		data() {
			return {
				rz_index_5:this.imgBasUrl2+'rz_index_5.png',
				rz_index_7:this.imgBasUrl2+'rz_index_7.png',
				rz_index_15:this.imgBasUrl2+'rz_index_15.png',
				rz_f_3:this.imgBasUrl2+'rz_f_3.png',
				rz_f_13:this.imgBasUrl2+'rz_f_13.png',
				rz_f_14:this.imgBasUrl2+'rz_f_14.png',
				activeIndex:1,
				setpArr:[
				   { title:'填写融资意向',img:require('@/assets/images/okPay/bangan_2.png'),activeImg:require('@/assets/images/okPay/bangan_1.png')},
				   { title:'等待主动联络',img:require('@/assets/images/okPay/step4.png'),activeImg:require('@/assets/images/okPay/step3.png')},
				   { title:'达成融资合作',img:require('@/assets/images/okPay/step6.png'),activeImg:require('@/assets/images/okPay/step5.png')},
				],
			}
		},
		methods: {
			activeTab(index) {
				this.activeIndex = index;
			}
		},
	};
</script>

<style>
	@import url('../../assets/css/web_h5/financing.css');
</style>
<style scoped="scoped">
	 .activeClass{
 	position: relative;
 }
 .activeClass:after{
 	content: '';
 	position: absolute;
    left: 0;
    bottom: 2px;
    width: 100%;
    height: 2px;
    background-color: #FFFFFF;
 }
</style>