<template>
	<div class="applyFinancing  widthMin">
	   <div class="applyFinancingBox">
	     <div class="app_f_r calFrom">
	     	<div class="f_title">请填写融资意向信息</div>
	       <el-form ref="ruleForm" :model="form" :rules="rules" label-width="80px">
			  <el-form-item label="项目名称" prop="projectName">
			    <el-input maxlength="40" v-model="form.projectName"></el-input>
			  </el-form-item>
			  <el-form-item label="融资金额" prop="amount">
			   <el-input type="number" placeholder="请输入内容" v-model="form.amount">
			    <template slot="append">万元</template>
			  </el-input>
			  </el-form-item>
			  <el-form-item label="公司名称" prop="companyName">
			    <el-input maxlength="40" v-model="form.companyName"></el-input>
			  </el-form-item>
			  <el-form-item label="项目描述" prop="proDescribe">
			    <el-input type="textarea" maxlength="200" show-word-limit :autosize="{ minRows: 5, maxRows: 8}" v-model="form.proDescribe"></el-input>
			  </el-form-item>
			  <template v-if="$store.state.isLogin === false">
			  	<el-form-item label="联系电话" prop="phone">
				    <div class="b_lr">
				    	<el-input class="b_l" maxlength="11" v-model="form.phone"></el-input>
				    	<el-button class="b_r q_blue" type="primary" @click="sonEvent"><countDown :getCodeEvent="sendCode" ref="countDown"></countDown></el-button>
				    </div>
				</el-form-item>
				<el-form-item label="验证码" prop="smsCode">
				    <el-input  v-model="form.smsCode"></el-input>
				</el-form-item>
			  </template>
			  <template v-else>
			  	<el-form-item label="联系电话" prop="phone">
				    <el-input maxlength="11"  v-model="form.phone"></el-input>
				</el-form-item>
			  </template>
			  <el-form-item>
			    <el-button type="primary" @click="onSubmit" class="fBtn">发布融资</el-button>
			  </el-form-item>
			</el-form>
	     </div>
	   </div>
	</div>
</template>

<script>
	import {sendSmsCode,addIntention} from "@/assets/js/api";
	export default {
		name:'ApplyFinancing',
		data() {
			return {
				form: {
		          amount: '',//融资金额
		          companyName: '',//公司名称
		          projectName:'',//项目名称
		          proDescribe:'',//项目描述
		          phone:'',
		          smsCode:''
		       },
		       rules:{
		       	  amount:[{ required: true, message: '请输入融资', trigger: 'blur' }],
		       	  companyName:[{ required: true, message: '请输入公司名称', trigger: 'blur' }],
		       	  projectName:[{ required: true, message: '请输入项目名称', trigger: 'blur' }],
		       	  proDescribe:[{ required: true, message: '请输入项目描述', trigger: 'blur' }],
		       	  phone:[{ required: true, message: '请输入联系电话', trigger: 'blur' }],
		       	  smsCode:[{ required: true, message: '请输入验证码', trigger: 'blur' }],
		       }
			}
		},
		created() {
			
		},
		methods:{
			onSubmit() {
				this.$refs['ruleForm'].validate((valid) => {
		          if (valid) {
		          	this.form.scene= 'PC_DEFAULT';
		            addIntention(this.form).then((res)=>{
			          if(res.code === '1'){
						this.$message.success(res.msg);
						this.$refs['ruleForm'].resetFields();
			          }else{
			            this.$message.error(res.msg)
			          }
			        },
			        (err)=>{
			          console.log(err)
			        })
		          } else {
		            return false;
		          }
		        });
			},
			rCalc() {
				const { href } = this.$router.resolve({
				    name: 'Calc',
				});
				window.open(href, '_blank');
			},
			sonEvent() {
		    	this.$refs.countDown.sonEvent();
		    },
		    sendCode () {
		      var _this = this;
		      let tel = this.form.phone;  
		      if(!/^1[345789]\d{9}$/.test(tel)){
		        this.$message.error('请输入正确手机号');
		      }else{
		        //调接口
		        sendSmsCode({
		          phone: tel, //手机号
		          scene  :'PC_DEFAULT' //场景  默认值
		        }).then((res)=>{
		          if(res.code === '1'){
		             this.$refs.countDown.codeTimer();
					_this.$message.success('短信验证码发送成功');
		          }else{
		            _this.$message.error(res.msg)
		          }
		        },
		        (err)=>{
		          console.log(err)
		        })
		      }  
		    },
		}
	}
</script>
<style>
	 .calFrom input{
  	height: 45px;
  	border: 1px solid #D6D6D6;
border-radius: 4px;
  }
</style>
<style scoped="scoped">
	.applyFinancingBox{
		padding: 30px 0;
		background-color: #FFFFFF;
		box-shadow: 0px 0px 9px 1px rgba(226, 226, 226, 0.35);
	}
	.applyFinancingBox .app_f_r{
		width: 500px;
		margin: 0 auto;
		padding: 0 30px;
	}
	.applyFinancingBox .app_f_r .f_title{
		padding-bottom: 30px;
		padding-left: 50px;
		font-size: 16px;
		text-align: center;
	}
	.b_lr{
		display: flex;
	}
	.b_lr .b_l{
		flex: 1;
	}
	.b_lr .b_r{
		margin-left: 20px;
	}
	.fBtn{
		width: 375px;
		height: 50px;
		background-color: #016FF1;
	}
	
</style>